export function requestImage(url,showImage) {
  let token = sessionStorage.getItem("token")
// 如果 token 存在
// 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    let request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('post', url, true);
    request.setRequestHeader('token', token);
    request.onreadystatechange = e => {
      if (request.readyState == XMLHttpRequest.DONE && request.status == 200) {
        showImage(URL.createObjectURL(request.response))
        // Image =  URL.createObjectURL(request.response)
      }
    };
    request.send(null);
  }else{
    return null;
  }
}