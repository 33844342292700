import request from "@/utils/request";

const api = {
  exportAttend: "/dsmcore/api/worksite/attendanceexport", // 导出此工地的考勤信息
  exportStopped: "/dsmcore/api/worksite/stopworkexport", // 导出暂停施工信息
  exportWorkers: "/dsmcore/api/worker/workerexport", // 导出列表人员信息
  exportWorkerAttend: "/dsmcore/api/worker/attendanceexport", // 导出工人考勤表
  exportDevices: "/dsmcore/api/device/deviceexport", // 导出设备列表数据
  exportDistribute: "/dsmcore/api/device/distribexport", // 导出本次分配信息
  importDevice: "/dsmcore/api/device/import", // 导入设备
  exportEvent: "/dsmcore/api/worker/eventexport", // 导出告警事件信息
  exportSiteList: "/dsmcore/api/worksite/workexport", // 导出工地列表
  getdeviceonoffexport: "/dsmcore/api/device/deviceonoffexport", //设备累计时长导出
  exportSiteNeat: "/dsmcore/api/worker/imageexport", //导出工地形象列表整洁度
  dcountbcexport: "/dsmcore/api/count/dcountbcexport", //设备统计分析按组织统计导出
  dcountwbossexport: "/dsmcore/api/count/dcountwbossexport", //设备统计分析按负责人统计导出
  acountworkerexport: "/dsmcore/api/count/acountworkerexport", //考勤统计按人员统计导出
  acountwsexport: "/dsmcore/api/count/acountwsexport", //考勤统计按工地统计导出
  wscountbcexport: "/dsmcore/api/count/wscountbcexport", //工地形象统计按组织统计导出
  wscountbossexport: "/dsmcore/api/count/wscountbossexport", //工地形象统计按负责人统计导出
  wscountwsexport: "/dsmcore/api/count/wscountwsexport", //工地形象统计按工地统计导出
};

export function ExportAttend(parameter) {
  return request({
    url: api.exportAttend,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportStopped(parameter) {
  return request({
    url: api.exportStopped,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportWorkers(parameter) {
  return request({
    url: api.exportWorkers,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportWorkerAttend(parameter) {
  return request({
    url: api.exportWorkerAttend,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportDevices(parameter) {
  return request({
    url: api.exportDevices,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportDistribute(parameter) {
  return request({
    url: api.exportDistribute,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportEvent(parameter) {
  return request({
    url: api.exportEvent,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportSiteList(parameter) {
  return request({
    url: api.exportSiteList,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function Getdeviceonoffexport(parameter) {
  return request({
    url: api.getdeviceonoffexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportSiteNeat(parameter) {
  return request({
    url: api.exportSiteNeat,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportDcountbc(parameter) {
  return request({
    url: api.dcountbcexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportDcountwbosse(parameter) {
  return request({
    url: api.dcountwbossexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportAcountworker(parameter) {
  return request({
    url: api.acountworkerexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportAcountws(parameter) {
  return request({
    url: api.acountwsexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}

export function ExportWscountbc(parameter) {
  return request({
    url: api.wscountbcexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportWscountboss(parameter) {
  return request({
    url: api.wscountbossexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
export function ExportWscountws(parameter) {
  return request({
    url: api.wscountwsexport,
    method: "post",
    params: parameter,
    responseType: "blob",
  });
}
