<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUserAttend"
      ref="SSearch"
      :bc_id="queryParam.bc_id"
    ></s-search>

    <div class="table-button table-button-flex">
      <div><a-button @click="goToAttendStatistics">查看考勤统计</a-button></div>
      <div>
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf('WorkerAttend-/api/worker/attendanceexport') > -1
          "
          >导出</a-button
        >
        <!-- <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      > -->
        <a-button type="plain" icon="redo" @click="refreshTable">刷新</a-button>
        <columns-set
          tableName="WorkerAttend"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <!--  :scroll="{ x: 1500, y: `calc(100vh - ${180 + 'px'})` }" -->
    <a-table
      ref="table"
      size="small"
      bordered
      rowKey="key"
      :columns="tableColumns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1500, y: 'calc(82vh - 150px )' }"
    >
      <!-- :scroll="{ x: 1000 }" -->
      <span slot="serial" slot-scope="text, record, index">
        <!-- {{ index + 1 }} -->
        {{ (current - 1) * pageSize + index + 1 }}
      </span>
      <span slot="w_post" slot-scope="text">
        {{ text }}
      </span>
      <span slot="w_type" slot-scope="text">
        {{ text }}
      </span>
      <!-- 新增工地名称 -->
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <!-- info框 -->
            <span>{{ text }}</span>
          </template>
          <!-- <span>{{ text }}</span> -->
          <span @click="jumpToSiteList(text)" class="cursor_pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
      <!-- 日期 ---->
      <span slot="a_time1" slot-scope="text, record">
        <div v-if="record.a_time1 == ''">{{ "-" }}</div>
        <div>{{ $Format(record.a_time1).slice(0, 10) }}</div>
      </span>
      <!-- 人脸照片 w_face -->
      <span slot="w_face" slot-scope="text, record" class="w_face">
        <a-popover title="人脸信息" trigger="hover" placement="left">
          <div v-if="record.w_face">
            <img :src="pathUrl + record.w_face" class="imgname" />
          </div>
          <div v-else>暂无图片</div>
          <div slot="content">
            <img :src="pathUrl + record.w_face" class="w-face-img" />
          </div>
        </a-popover>
      </span>
      <!-- 首次打卡照片 -->
      <span slot="a_photo1" slot-scope="text, record" class="info_out">
        <span
          placement="left"
          :class="record.a_contrast1 ? 'Image info' : ' Image'"
          @click="showInfo1(record, record.serial)"
        >
          <div v-if="record.a_photo1" class="actions">
            <div slot="content" class="icon_out">
              <img :src="pathUrl + record.a_photo1" />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
          <div v-else>暂无图片</div>
        </span>
        <div style="display: flex; justify-content: space-between">
          <!-- 打卡时间 -->
          <div>
            {{ record.a_time1 ? $Format(record.a_time1).slice(10) : "" }}
          </div>
          <!-- 添加打卡对比度-- -->
          <div>
            {{ record.a_contrast1 ? record.a_contrast1 + "%" : "" }}
          </div>
        </div>
        <div>
          {{ record.a_d_imei1 }}
        </div>
      </span>

      <!-- 末次打卡照片 -->
      <span slot="a_photo2" slot-scope="text, record" class="info_out">
        <span
          placement="left"
          :class="record.a_contrast2 ? 'Image info' : ' Image'"
          @click="showInfo2(record, record.serial)"
        >
          <div v-if="record.a_contrast2" class="actions">
            <div class="icon_out">
              <img :src="pathUrl + record.a_photo2" class="face-img" />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
          <div v-else>暂无图片</div>
        </span>
        <div style="display: flex; justify-content: space-between">
          <!-- 打卡时间 -->
          <div>
            {{ record.a_time2 ? $Format(record.a_time2).slice(10) : "" }}
          </div>
          <!-- 添加打卡对比度-- -->
          <div style="margin-right: 12px">
            {{ record.a_contrast2 ? record.a_contrast2 + "%" : "" }}
          </div>
        </div>
      </span>
      <!-- 工作时长 ---->
      <span slot="a_duration" slot-scope="text">
        {{
          text.split("-")[0] +
          "小时 " +
          text.split("-")[1] +
          "分" +
          text.split("-")[2] +
          "秒"
        }}
      </span>

      <!-- 考勤 -->
      <span
        slot="calendar"
        slot-scope="text, record"
        v-if="isShowBtns.indexOf('WorkerAttend-/api/worker/calendar') > -1"
      >
        <a-popover
          :title="record.w_name + '该月考勤记录日期'"
          trigger="click"
          placement="left"
          @visibleChange="visibleChangeCalendar"
        >
          <div slot="content" style="display: flex">
            <!-- 上上月考勤日历 -->
            <div>
              <div class="monthShow">{{ preDobulecalendar_pre_doble }}</div>
              <a-calendar
                :fullscreen="false"
                @panelChange="onPanelChange"
                :value="preDobulecalendar_pre_doble"
                class="preDobulecalendar"
              >
                <span slot="dateFullCellRender" slot-scope="value">
                  <span
                    v-if="
                      calendarData.includes($moment(value).format('YYYY/MM/DD'))
                    "
                    class="events"
                    >{{ $moment(value).format("DD") }}</span
                  >
                  <span v-else>{{ $moment(value).format("DD") }}</span>
                </span>
              </a-calendar>
            </div>
            <!-- 上月考勤日历 -->
            <div>
              <div class="monthShow">{{ preDobulecalendar }}</div>
              <!-- :defaultValue="preDobulecalendar" -->
              <a-calendar
                class="preDobulecalendar"
                :fullscreen="false"
                @panelChange="onPanelChange"
                :value="preDobulecalendar"
              >
                <span slot="dateFullCellRender" slot-scope="value">
                  <span
                    v-if="
                      calendarData.includes($moment(value).format('YYYY/MM/DD'))
                    "
                    class="events"
                    >{{ $moment(value).format("DD") }}</span
                  >
                  <span v-else>{{ $moment(value).format("DD") }}</span>
                </span>
              </a-calendar>
            </div>
            <!-- 当月考勤日历 -->
            <div>
              <a-calendar
                :fullscreen="false"
                @panelChange="onPanelChange"
                :value="nowcalendar"
              >
                <span slot="dateFullCellRender" slot-scope="value">
                  <span
                    v-if="
                      calendarData.includes($moment(value).format('YYYY/MM/DD'))
                    "
                    class="events"
                    >{{ $moment(value).format("DD") }}</span
                  >
                  <span v-else>{{ $moment(value).format("DD") }}</span>
                </span>
              </a-calendar>
            </div>
          </div>
          <a @click="showCalendar(record)"
            ><a-icon type="schedule" class="CalendarClassName"
          /></a>
        </a-popover>
      </span>
    </a-table>
    <a-pagination
      size="middle"
      v-model="current"
      :pageSize="pageSize"
      @change="pageNumberChange"
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :total="total"
      showSizeChanger
      style="margin: 20px 0; text-align: right"
    />

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>

    <a-modal
      width="1120px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 1100px">打卡照片{{ ImgName }}</div>
      <div id="Image">
        <img :src="Image1" class="face-img-big" />
        <img :src="Image2" class="face-img-big" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image1 != '' ? Image1 : Image2)">
            下载图片
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { SSearch, ColumnsSet } from "@/components";
import { GetWorkerAttend, CetAttendCalender } from "@/api/worker";
import { ExportWorkerAttend } from "@/api/export";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";
import moment from "moment";
// 搜索条件
const queryItems = [
  // { label: "员工ID", value: "w_id", type: "input" },
  { label: "员工姓名", value: "w_name", type: "input", defaultValue: "" },
  {
    label: "岗位",
    value: "w_post",
    type: "select",
    selectOption: "WorkPostWorkAttent",
  },
  {
    label: "工种",
    value: "w_type",
    type: "select",
    selectOption: "WorkTypeWorkAttent",
  },
  {
    label: "人员所属分公司",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
  },
  // 新增
  { label: "工地名称", value: "ws_name", type: "input" },
  { label: "工地所属工程管家", value: "w_gj_name", type: "input" },
  { label: "工地所属项目经理", value: "w_jl_name", type: "input" },
  // { label: "查询日期", value: "a_time", type: "date" },
  {
    label: "人员考勤日期查询",
    value: "a_time",
    type: "dateRange",
    range: ["a_time_start", "a_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "考勤类型",
    value: "attendance_type",
    type: "select",
    selectOption: "AttendanceType",
  },
];

const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "日期",
    width: "120px",
    scopedSlots: { customRender: "a_time1" },
    dataIndex: "a_time1",
    align: "center",
  },
  {
    title: "姓名",
    width: "90px",
    dataIndex: "w_name",
    scopedSlots: { customRender: "w_name" },
    align: "center",
  },
  {
    title: "岗位/工种",
    width: "100px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_post" },
    align: "center",
  },
  // 新增
  {
    title: "工地名称",
    // width: "300px",
    dataIndex: "ws_name",
    /**
     * 如果要自定义该列表格内容的显示，需要写scopedSlots
     * 具体自定义内容见template中<s-table>的写法
     */
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "工地所属工程管家",
    width: "70px",
    dataIndex: "w_gj_name",
    align: "center",
  },
  {
    title: "工地所属项目经理",
    width: "70px",
    dataIndex: "w_jl_name",
    align: "center",
  },
  // {
  //   title: "所属项目经理",
  //   width: "120px",
  //   dataIndex: "w_jl",
  // },
  // {
  //   title: "首次打卡时间",
  //   width: "150px",
  //   scopedSlots: { customRender: "a_time1" },
  //   dataIndex: "a_time1",
  // },
  {
    title: "工作时长",
    width: "120px",
    dataIndex: "a_duration",
    scopedSlots: { customRender: "a_duration" },
  },
  {
    title: "人脸照片",
    width: "100px",
    scopedSlots: { customRender: "w_face" },
    dataIndex: "w_face",
    align: "center",
    fixed: "right",
  },

  {
    title: "上班打卡照片",
    width: "120px",
    scopedSlots: { customRender: "a_photo1" },
    dataIndex: "a_photo1",
    fixed: "right",
  },

  {
    title: "下班打卡照片",
    width: "120px",
    scopedSlots: { customRender: "a_photo2" },
    dataIndex: "a_photo2",
    fixed: "right",
  },

  {
    title: "考勤日历",
    width: "90px",
    scopedSlots: { customRender: "calendar" },
    dataIndex: "calendar",
    align: "center",
    fixed: "right",
  },
];
const orderParam = [
  "ws_name",
  "w_name",
  "w_type",
  // "a_time",
  "a_time_start",
  "a_time_end",
  "w_post",
  "w_jl_name",
  "w_gj_name",
  "attendance_type",
  "bc_id",
];

export default {
  name: "WorkerAttend",
  components: {
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    const tableSize = { x: 1500, y: document.body.clientHeight - 380 };
    return {
      tableColumns: [],
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      loading: false,
      dataSource: [],
      data: [],
      // 查询参数
      queryParam: {
        a_time_start: "",
        a_time_end: "",
        ws_name: "",
        attendance_type: "",
        w_name: "",
        w_post: "",
        bc_id: "",
      },
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      calendarData: [],
      visible: false,
      confirmLoading: false,
      visibleImg: false,
      ImgName: "",
      Image1: "",
      Image2: "",
      tableSize,
      preDobulecalendar: null,
      preDobulecalendar_pre_doble: null,
      nowcalendar: null,
      isShowBtns: [],
    };
  },

  created() {
    this.tableColumns = columns;
    this.changeColumns();
    // 默认值为七天前的日期和今天的日期
    // var sevenTime = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
    // 更改为当天的日期
    var sevenTime = new Date().setHours(0, 0, 0, 0);

    // let date = this.$Format(new Date().setHours(0, 0, 0, 0) / 1000);
    // console.log(date);
    this.queryItems[7].defaultValue = [
      moment(sevenTime),
      moment(moment(new Date())),
    ];
    this.queryParam.a_time_start = Math.round(sevenTime / 1000);
    this.queryParam.a_time_end = Math.round(new Date().getTime() / 1000);
    // console.log(new Date().getTime());
    // console.log(this.$route.query);
    if (this.$route.query && Object.keys(this.$route.query) != "") {
      setTimeout(() => {
        this.$refs.SSearch.expandChange();
      }, 10);
      // console.log("1111", this.$route.query);
      // console.log(this.$Format(this.$route.query.start_time));
      // this.queryParam.ws_name = this.$route.query.name;
      if (this.$route.query.start_time != "") {
        this.queryParam.a_time_start = this.$route.query.start_time;
        this.queryParam.a_time_end = this.$route.query.end_time;
        this.queryItems[7].defaultValue = [
          moment(this.$Format(this.$route.query.start_time)),
          moment(this.$Format(this.$route.query.end_time)),
        ];
      }
      // 员工姓名
      // this.queryItems[0].defaultValue = this.$route.query.w_name;
      // this.queryParam.w_name = this.$route.query.w_name;

      for (let item in this.$route.query) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == item) {
            el.defaultValue = this.$route.query[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          if (index == item) {
            this.queryParam[index] = this.$route.query[item];
          }
        }
      }
    } else {
    }
    this.dataLoad();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  watch: {
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
    nowcalendar(newValue) {
      console.log("nowcalendar", newValue);
      let preChangeMM = newValue.split("-")[1] * 1 - 1;
      let preChangeYY = newValue.split("-")[0] * 1;
      let preDobuleChangeMM = preChangeMM * 1 - 1;
      this.preDobulecalendar = this.getTime(preChangeYY, preChangeMM);
      this.preDobulecalendar_pre_doble = this.getTime(
        preChangeYY,
        preDobuleChangeMM
      );
      if (preChangeMM == 0) {
        this.preDobulecalendar = this.getTime(preChangeYY - 1, 12);
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 11);
      }
      if (preDobuleChangeMM == 0) {
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 12);
      }
    },
    deep: true,
    immediate: true, // 第一次改变就执行
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("WorkerAttendColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    //  分页显示条数的变化
    sizeChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      //后端返回的全部数据
      let keyValue = 0;
      this.total = data.length;
      for (let i = 0; i < this.total; i++) {
        keyValue = keyValue + 1;
        let key = { key: keyValue };
        data[i] = Object.assign(data[i], key);
      }
      this.loading = false;
      let start = this.pageSize * this.current - this.pageSize;
      let end = this.pageSize * this.current;
      let dataSource = data.slice(start, end);
      this.dataSource = dataSource;
      this.$set(this.dataSource, [], dataSource);
    },
    // 加载数据方法 必须为 Promise 对象
    dataLoad() {
      this.loading = true;
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        // ws_status: "",
        // d_status: "",
        // ws_bind_status: "",
        // begin_time: "",
        // end_time: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      GetWorkerAttend(params).then((res) => {
        this.data = res.data;
        this.getShowSource();
      });
    },
    //查询触发
    fetchUserAttend(value) {
      this.current = 1;
      this.queryParam = value;
      this.dataLoad();
      // this.$refs.table.refresh(true);
    },
    // 刷新
    refreshTable() {
      this.current = 1;
      this.dataLoad();
    },
    //导出
    handleExport() {
      this.visible = true;
    },
    // 跳转至考勤统计
    goToAttendStatistics() {
      localStorage.setItem("path", "AttendStatistics");
      this.$router.push({
        path: "/AttendStatistics",
        // query: {
        //   start_time: this.start_time,
        //   end_time: this.end_time,
        //   w_name: record.w_name,
        //   // attendance_type: attendance_type,
        // },
      });
    },
    //确认导出
    exportOk() {
      this.confirmLoading = true;
      const params = {
        ws_name: this.queryParam.ws_name,
        w_name: this.queryParam.w_name,
        w_type: this.queryParam.w_type,
        a_time_start: this.queryParam.a_time_start,
        a_time_end: this.queryParam.a_time_end,
        w_post: this.queryParam.w_post,
        w_jl_name: this.queryParam.w_jl_name,
        w_gj_name: this.queryParam.w_gj_name,
        attendance_type: this.queryParam.attendance_type,
      };

      ExportWorkerAttend(params).then((res) => {
        var blob = res;

        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "人员考勤列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visible = false;
        this.confirmLoading = false;
      });
    },
    //取消导出
    exportCancel() {
      this.visible = false;
    },
    //人员考勤日历显示
    showCalendar(record) {
      this.preDobulecalendar = this.getNowTime(0);
      this.preDobulecalendar_pre_doble = this.getNowTime(-1);
      this.nowcalendar = this.getNowTime(1);

      let params = {
        w_id: record.w_id,
        // time_start: record.a_time1,
        // time_end: record.a_time2,
      };
      CetAttendCalender(params).then((res) => {
        let calendarDate = [];
        res.data.forEach((el) => {
          let a_time = this.$moment(el.a_time * 1000).format("YYYY/MM/DD");
          calendarDate.push(a_time);
        });
        this.calendarData = calendarDate;
      });
    },
    // 日历弹出面板打开关闭的回调
    visibleChangeCalendar(e) {
      console.log("visibleChangeCalendar", e);
      // this.nowcalendar = this.getNowTime(1);
      console.log(this.nowcalendar);
      let newValue = this.nowcalendar;
      let preChangeMM = newValue.split("-")[1] * 1 - 1;
      let preChangeYY = newValue.split("-")[0] * 1;
      let preDobuleChangeMM = preChangeMM * 1 - 1;
      this.preDobulecalendar = this.getTime(preChangeYY, preChangeMM);
      this.preDobulecalendar_pre_doble = this.getTime(
        preChangeYY,
        preDobuleChangeMM
      );
      if (preChangeMM == 0) {
        this.preDobulecalendar = this.getTime(preChangeYY - 1, 12);
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 11);
      }
      if (preDobuleChangeMM == 0) {
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 12);
      }
    },
    // 日期面板变化回调
    onPanelChange(value, mode) {
      // console.log(value, mode);
      // 中间
      let preChangeMM = moment(value).format("MM") * 1 - 1;
      let preChangeYY = moment(value).format("YYYY") * 1;
      // console.log(preChangeMM, preChangeYY);
      //第一个
      let preDobuleChangeMM = moment(value).format("MM") * 1 - 1;
      this.preDobulecalendar = this.getTime(preChangeYY, preChangeMM);
      this.preDobulecalendar_pre_doble = this.getTime(
        preChangeYY,
        preDobuleChangeMM
      );
      this.nowcalendar = this.getTime(preChangeYY, moment(value).format("MM"));
      if (preChangeMM == 0) {
        this.preDobulecalendar = this.getTime(preChangeYY - 1, 12);
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 11);
      }
      // console.log(preChangeYY, this.getTime(2024 - 1, 12));
      if (preDobuleChangeMM == 0) {
        this.preDobulecalendar_pre_doble = this.getTime(preChangeYY - 1, 12);
      }
    },
    // 点击放大首次打卡照片
    showInfo1(record, serial) {
      this.visibleImg = true;
      this.ImgName = serial;
      this.Image1 = "";
      this.Image2 = "";
      let that = this;
      // 打卡照片
      // pathUrl + record.a_photo1
      requestImage(this.pathUrl + record.a_photo1, function (url) {
        that.Image1 = url;
      });
      // document.body.classList.remove("body-overflow-hidden");
    },
    // 末次打卡照片
    showInfo2(record, serial) {
      this.visibleImg = true;
      this.ImgName = serial;
      this.Image1 = "";
      this.Image2 = "";
      let that = this;
      // 打卡照片
      requestImage(this.pathUrl + record.a_photo2, function (url) {
        that.Image2 = url;
      });
    },
    imgCancel() {
      this.visibleImg = false;
    },
    // 获取月份  i为1的时候为本月
    getNowTime(i) {
      const nowDate = new Date();
      let [year, month, day, hour, minute, second] = [
        nowDate.getFullYear(),
        nowDate.getMonth() + i,
        // nowDate.getMonth(),

        nowDate.getDate(),
        nowDate.getHours(),
        nowDate.getMinutes(),
        nowDate.getSeconds(),
      ];
      // getDay() 获取的是星期
      // if (month < 10) {
      //   month = "0" + month;
      // } else {
      //   month = month;
      // }
      return (
        year + "-" + month
        // year + "年" + month + "月"
        //  + day + "日"
        // +
        // hour +
        // ":" +
        // minute +
        // ":" +
        // second
      );
    },
    getTime(y, m) {
      const nowDate = new Date();
      let [year, month, day] = [y, m, nowDate.getDate()];

      return year + "-" + month;
    },
    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          // ws_name: this.record.ws_name,
          ws_name: text,
        },
      });
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.face-img-big {
  width: 1080px;
}

.Image img {
  width: 100%;
  // height: 80px;
  height: 100%;
}

.events {
  color: #fff;
  padding: 0 5px;
  background-color: #1890ff;
}
.imgname1 {
  width: 300px;
  // width: 70%;
  // height: 70%;
  height: 420px;
  object-fit: cover;
}
// 鼠标悬停蒙层
.icon_out {
  width: 100%;
  // height: 85px;
  height: 90%;
  // position: relative;
  display: flex;
}
.icon {
  font-size: 25px;
  left: 43%;
  // top: 40%;
  top: 25%;
  position: absolute;
  margin: auto;

  display: none;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;
    // width: 150px;
    // width: 218px;
    // width: 145px;
    width: 100%;
    // height: 82px;
    // height: 100%;
    height: 75%;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
.imgname {
  width: 90%;
  // height: 100%;
  height: 80px;
  // height: 90px;
  object-fit: cover;
  // margin-top: -18px;
}
.w-face-img {
  width: 200px;
  height: 250px;
}
.w_face {
  width: 100%;
  height: 100%;
}
.CalendarClassName {
  font-size: 30px;
  color: rgb(55, 56, 56);
}
.preDobulecalendar {
  // /deep/ .ant-select.ant-fullcalendar-year-select.ant-select-sm,
  // /deep/ .ant-fullcalendar-header .ant-radio-group {
  //   display: none;
  // }
  /deep/ .ant-fullcalendar-header {
    display: none;
  }
}
.monthShow {
  text-align: center;
  margin-top: 15px;
  font-weight: 800;
}
/deep/ .ant-fullcalendar-header .ant-radio-group {
  display: none;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
